<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <div class="card-title">
        <div class="d-flex align-items-center position-relative my-1">
          <input
            type="text"
            v-model="searchParams.search"
            v-on:keyup.enter="handleSearch"
            class="form-control form-control-solid w-250px me-2"
            :placeholder="constants.artSku.ART_SEARCH"
          />
        </div>
        <el-tooltip class="box-item" effect="dark" :content="constants.general.SEARCH" placement="top">
          <button type="button" @click.prevent="handleSearch" class="btn btn-icon btn-light btn-sm me-2"><i class="bi bi-search"></i></button>
        </el-tooltip>
        <el-tooltip class="box-item" effect="dark" :content="constants.general.RESET" placement="top">
          <button type="button" @click.prevent="resetSearch" class="btn btn-icon btn-light btn-sm"><i class="bi bi-arrow-counterclockwise"></i></button>
        </el-tooltip>
      </div>
      <div class="card-toolbar">
        <div class="col-md-auto me-0 ms-auto me-2" v-if="permission.isImportAllowed || permission.isExportAllowed || permission.isArtHistoryAllowed">
        <a
          href="javascript:void(0);"
          class="btn btn-purple"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
          v-if="permission.isImportAllowed || permission.isExportAllowed || permission.isArtHistoryAllowed"
          ><i class="bi bi-file-earmark-arrow-up fs-4"></i> {{ constants.artSku.IMPORT_ART_BTN }}
          <span class="svg-icon svg-icon-5 m-0">
            <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
          </span>
        </a>
        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-purple fw-bold fs-7 w-250px py-4" data-kt-menu="true">
          <div class="menu-item px-3" v-if="permission.isImportAllowed">
            <a @click="$router.push({ name: 'import-art-SKU' })" class="menu-link px-3" ><i class="bi bi-file-earmark-arrow-up fs-4"></i>&nbsp; {{ constants.artSku.ART_IMPORT }}</a>
          </div>
          <div class="menu-item px-3" v-if="permission.isArtHistoryAllowed">
            <a @click="$router.push({ name: 'import-art-SKU-history' })" class="menu-link px-3"><i class="bi bi-clock-history fs-4"></i>&nbsp; {{ constants.artSku.ART_SKU_HISTORY }}</a>
          </div>
        </div>
      </div>

      <div class="col-md-auto me-0 ms-auto me-2" v-if="permission.isImportAllowed || permission.isExportAllowed || permission.isArtHistoryAllowed">
        <a
          href="javascript:void(0);"
          class="btn btn-warning"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="top-end"
          v-if="permission.isExportAllowed"
          ><i class="bi bi-file-earmark-arrow-down fs-4"></i> {{ constants.artSku.EXPORT_ART_BTN }}
          <span class="svg-icon svg-icon-5 m-0">
            <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
          </span>
        </a>
        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-purple fw-bold fs-7 w-250px py-4" data-kt-menu="true">
          <div class="menu-item px-3" v-if="permission.isExportAllowed">
            <a @click="exportArtSku('xlsx')" class="menu-link px-3"><i class="bi bi-file-earmark-arrow-down fs-4"></i>&nbsp; {{ constants.artSku.ART_SKU_EXCEL_EXPORT }}</a>
          </div>
          <div class="menu-item px-3" v-if="permission.isArtHistoryAllowed">
            <a @click="exportArtSku('csv')" class="menu-link px-3"><i class="bi bi-file-earmark-arrow-down fs-4"></i>&nbsp; {{ constants.artSku.ART_SKU_CSV_EXPORT }}</a>
          </div>
        </div>
      </div>
        <button type="button" class="btn btn-primary" v-if="permission.isAddAllowed" @click="$router.push({ name: 'add-art-sku' })"><i class="bi bi-plus-circle-fill fs-4"></i>
          {{ constants.general.ADD }}
        </button>
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :rows-per-page="searchParams.recordsPerPage"
        :empty-table-text="noDataFound"
        :loading="loading"
        :current-page="1"
        :enable-items-per-page-dropdown="true"
        :total="total"
        ref="updateProps"
        @current-change="handleCurrentChange"
        @items-per-page-change="handleSizeChange"
      >
        <template v-slot:cell-id="{ row: data }">
          {{ data }}
        </template>
        <template v-slot:cell-art_sku_name="{ row: data }">
          {{ data.art_sku_name != null ? data.art_sku_name : '-' }}
        </template>
        <template v-slot:cell-art_sku_code="{ row: data }">
          {{ data.art_sku_code }}
        </template>
        <template v-slot:cell-actions="{ row: data }">
           <div v-if="permission.isEditAllowed || permission.isDeleteAllowed">
              <el-tooltip class="box-item" v-if="permission.isEditAllowed" effect="dark" :content="constants.general.EDIT" placement="top">
                <button type="button"  @click.prevent="$router.push({ name: 'edit-art-sku', params: { id: data.art_sku_id } })" class="btn btn-icon btn-light btn-sm me-2">
                  <i class="bi bi-pencil-square"></i>
                </button>
              </el-tooltip>
              <el-tooltip class="box-item" v-if="permission.isDeleteAllowed" effect="dark" :content="constants.general.DELETE" placement="top">
                <button type="button"  @click.prevent="remove(data.art_sku_id, tableData.findIndex((i) => i.art_sku_id === data.art_sku_id))" class="btn btn-icon btn-light btn-sm">
                  <i class="bi bi-trash text-danger"></i>
                </button>
              </el-tooltip>
           </div>
           <div v-else>
             -
           </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { notificationFire } from "@/composable/notification.js";
import { ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";
import { Actions } from "@/store/enums/RememberSearchEnums";
import { useStore } from "vuex";
import axios from "axios";

export default {
  name: "art-sku-listing",
  components: {
    Datatable
  },
  setup() {
    let total = ref(0);
    const store = useStore();
    const tableData = ref([]);
    const loading = ref(false);
    const updateProps = ref(null);
    const route = useRoute();
    const moduleName = ref((route.meta.module) ? route.meta.module : '');
    const constants = globalConstant;
    const noDataFound = ref(globalConstant.general.LOADING);
    const searchParams = reactive({
      search: store.getters.getSearch(moduleName.value),
      recordsPerPage: store.getters.getPerPage(moduleName.value),
      pageNub: store.getters.getPageNumber(moduleName.value),
    });
    const permission = reactive({
      isAddAllowed: false,
      isEditAllowed: false,
      isDeleteAllowed: false,
      isImportAllowed: false,
      isExportAllowed: false,
      isArtHistoryAllowed: false
    })
    const tableHeader = ref([
      {
        name: constants.general.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
      },
      {
        name: constants.artSku.ART_SKU_NAME,
        key: "art_sku_name",
        sortable: true,
        width: "w-100px",
      },
      {
        name: constants.artSku.ART_SKU_CODE,
        key: "art_sku_code",
        sortable: true,
        width: "w-100px",
      },
      {
        name: constants.general.ACTIONS,
        key: "actions",
        width: "w-200px",
        sortable: false,
      },
    ]);

    //Get all Art SKu
    const getArtSku = async () => {
      loading.value = true;
      await ApiService.query("art-sku", { params: searchParams })
        .then(({ data }) => {
          if (data.data.data.length) {
            tableData.value.splice(0, tableData.value.length, ...data.data.data);
            total.value = data.data.total;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub);
          } else {
            tableData.value = [];
            total.value = 0;
            updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, globalConstant.general.NO_DATA_FOUND);
          }
          setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]);
          loading.value = false;
        })
        .catch((error) => {
          tableData.value = [];
          loading.value = false;
        });
    };

    //Remove art sku data from listing page
    const remove = async (artSkuID, index) => {
      ElMessageBox.confirm(
        constants.general.DELETE_MESSAGE,
        constants.general.ALERT_WARNING,
        {
          confirmButtonText: constants.general.ALERT_CONFIMATION,
          cancelButtonText: constants.general.ALERT_CANCEL,
          type: 'warning',
          center: true
        }
      ).then(() => {
        ApiService.delete("art-sku/" + artSkuID)
          .then((response) => {
            let status = response.status;
            let message = response.data.message;
            if (status == 200) {
              notificationFire(message, "success");
              tableData.value.splice(index, 1);
              total.value = total.value > 0 ? total.value - 1 : 0;
              setCurrentPageBreadcrumbs(formatText(route.name) + " (" + total.value + ")", [{ name: formatText(route.name), active: true }]
              );
              if(tableData.value.length == 0){
                updateProps.value.updateProps(total.value, searchParams.recordsPerPage, searchParams.pageNub, globalConstant.general.NO_DATA_FOUND);
              }
            }
          })
          .catch((error) => {
            let message = error.message;
            notificationFire(message, "error");
          });
      });
    };

    //Export all art sku in xlsx file
    const exportArtSku = async (extension) => {
      if(extension != "" && (extension == 'csv' || extension == 'xlsx')){
        axios({
          url: 'art-sku/export',
          method: 'POST',
          data: {
            search: searchParams.search,
            extension:extension
          },
          responseType: 'arraybuffer',
        }).then((response) => {
          let blob = new Blob([response.data], {
              type: ''
          })
          let link = document.createElement('a')
          link.setAttribute('target','_blank')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'Art_SKU_'+Date.now()+'.'+extension
          link.click()
        })
        .catch((error) => {
          let response = error;
            if (response != "") {
              notificationFire('Art sku data not found', "error");
            }
        });
      }else{
        notificationFire('Something went wrong.', "error");
      }
    }

    //Page size change event
    const handleSizeChange = (number) => {
      store.dispatch(Actions.PER_PAGE, {'module':moduleName.value,'value':number});
      searchParams.recordsPerPage = store.getters.getPerPage(moduleName.value);
      handleCurrentChange(1);
    };

    //Page change event
    const handleCurrentChange = (number) => {
      store.dispatch(Actions.PAGE_NUMBER, {'module':moduleName.value,'value':number});
      searchParams.pageNub = store.getters.getPageNumber(moduleName.value);
      getArtSku();
    };

    //Handle search event
    const handleSearch = () => {
      store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
      handleCurrentChange(1);
    };

    //Reset search value
    const resetSearch = () => {
      searchParams.search = "";
      store.dispatch(Actions.SEARCH, {'module':moduleName.value,'value':searchParams.search});
      getArtSku();
    };

    //Set breadcrumbs and get the art sku
    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{ name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("createArtSku") || role == "Super Admin")
        permission.isAddAllowed = true;
      if (per.includes("updateArtSku") || role == "Super Admin")
        permission.isEditAllowed = true;
      if (per.includes("deleteArtSku") || role == "Super Admin")
        permission.isDeleteAllowed = true;
      if (per.includes("importArtSku") || role == "Super Admin")
        permission.isImportAllowed = true;
      if (per.includes("exportArtSku") || role == "Super Admin")
        permission.isExportAllowed = true;
      if (per.includes("importArtSkuHistory") || role == "Super Admin")
        permission.isArtHistoryAllowed = true;
      await getArtSku();
    });

    return {
      tableData,
      getArtSku,
      total,
      loading,
      searchParams,
      handleSearch,
      handleSizeChange,
      handleCurrentChange,
      remove,
      resetSearch,
      tableHeader,
      noDataFound,
      updateProps,
      constants,
      permission,
      exportArtSku
    };
  },
};
</script>
<style lang="scss">
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(2.1em + 1.1rem + 2px);
  width: calc(2.1em + 1.1rem + 2px);
}
// For Import Button
  .btn.btn-purple{
    background-color: #8618cbbf;
    border-color: #8618cbbf;
    color:#ffffff;
  }
  .btn.btn-purple i{color:#ffffff;}
  .btn.btn-purple svg path{fill:#ffffff !important;}
  .btn.btn-purple:hover,.btn.btn-purple:focus,.btn.btn-purple:active{
    background-color: #6911a1bf;
    border-color: #6911a1bf;
  }
  .menu-state-bg-light-purple .menu-item.hover:not(.here) > .menu-link:not(.disabled):not(.active):not(.here), .menu-state-bg-light-purple .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here){
    transition: color 0.2s ease, background-color 0.2s ease;
    background-color: #f5e7ff96;
    color: #8618cbbf;
  }
  .attribute-name {
    color:#009EF7;
  }
</style>